html {
  /* 14px for mobile & tablet */
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    /* 16px for desktop */
    font-size: 16px;
  }
}

body {
  background-color: #fff;
}

.pricing-header {
  /* limit header width so that the paragraph is easier to read*/
  max-width: 700px;
}

.container {
  /* overwirte all max-width value smaller than 960px in all media queries */
  /* overwriting max-width 540 @ mw 576 & max-width 720 @ mw 768 */
  /* providing wider width (smaller x-margin) across tablet and dektop */
  max-width: 960px;
}

.card-deck .card {
  /* make sure the cards don't get too narrow */
  min-width: 220px;
}

.card-deck {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.card {
  max-width: 400px;
  
}

footer h5 {
  text-transform: capitalize;
}

footer i > svg {
  width: 2rem;
  height: 2rem;
  color: rgb(76, 54, 113);
}

